import { useUserDetails } from "~/stores/userStore";
export default defineNuxtRouteMiddleware(async (to, from) => {
    // skip middleware on server
    if (process.server) return;

    const { getAccessToken } = await useUserDetails();

    const shouldRedirectToLogin = ref(false);

    if (!getAccessToken) {
        shouldRedirectToLogin.value = true;
    }

    if (shouldRedirectToLogin.value) {
        console.warn("Unauthorized Access Detected");
        return navigateTo({
            path: "/",
            query: {
                uauthacc: "1",
            },
        });
    }
});
